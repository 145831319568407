/*--
/*  4.13 - Cart CSS
/*----------------------------------------*/

// cart Table 
.cart-table{
    & .table{
        margin-bottom: 0;   
        & thead{
            & tr{
                & th{
                    background-color: #ebebeb;
                    border: 1px solid #ebebeb;
                    border-bottom: 0;
                    padding: 10px 20px;
                    text-align: center;
                    font-size: 16px;
                    white-space: nowrap;
                    color: $dark;

                    &.Product-thumb{
                        min-width: 150px;
                        width: 150px;
                    }
                    &.Product-info{
                        min-width: 330px;
                        text-align: left;
                    }
                    &.Product-quantity{
                        min-width: 120px;
                    }
                    &.Product-total-price{
                        min-width: 90px;
                    }
                    &.Product-add-cart{
                        min-width: 120px;
                    }
                    &.Product-action{
                        min-width: 50px;
                    }
                }
            }
        }
        & tbody{
            & tr{
                & td{
                    border: 1px solid #ebebeb;
                    padding: 20px 20px;
                    vertical-align: middle;

                    &.Product-thumb{
                        & img{

                        }
                    }
                    &.Product-info{
                        & .name{
                            & a {
                                text-decoration: none;
                                font-weight: 600;
                                line-height: 1;
                                color: $dark;
                                font-size: 14px;
                                text-transform: capitalize;
                                transition: all 0.3s linear;

                                &:hover{
                                    color: $primary;
                                }
                            }
                        }
                        & .product-prices{
                            margin-top: 15px;
                    
                            & .old-price{
                                font-weight: 400;
                                line-height: 20px;
                                font-size: 14px;
                                color: #b1b1b1;
                                margin-right: 8px;
                                text-decoration: line-through;
                                display: inline-block;
                            }
                            & .sale-price{
                                color: $primary;
                                font-size: 20px;
                                font-weight: 500;
                                line-height: 30px;
                                display: inline-block;
                                margin-right: 8px;
                            }
                            & .discount-percentage{
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 26px;
                                color: $white;
                                padding: 0 10px 0 10px;
                                background: $dark;
                                vertical-align: 5px;
                                border-radius: 3px;
                                text-transform: capitalize;        
                                display: inline-block;    
                            }
                        }
                        & .product-size-color{
                            & p{
                                font-size: 14px;
                                margin-bottom: 0px;
                                margin-top: 5px;
                            }
                        }
                    }
                    &.quantity{
                        text-align: center;

                        & .product-quantity{
                            position: relative;
                            padding-right: 24px;
                    
                            & button{
                                position: absolute;
                                background: none;
                                top: 0;
                                right: 0;
                                border: 1px solid #ebebeb;
                                padding: 0;
                                width: 24px;
                                height: 24px;
                                font-size: 14px;
                                transition: all 0.3s linear;
                    
                                &:hover{
                                    background-color: #f1f1f1;
                                }
                            }
                            & input{
                                width: 60px;
                                height: 48px;
                                border: 1px solid #ebebeb;
                                border-right: 0;
                                padding: 5px 8px;
                                font-size: 14px;
                                outline: none;
                            }
                            & *{
                                &:first-child{
                                    top: auto;
                                    bottom: 0;
                                }
                            }
                        }
                    }
                    &.Product-total-price{
                        text-align: center;
                        & .price{
                            font-size: 18px;
                        }
                    }
                    &.Product-add-cart{
                       text-align: center;
                    }
                    &.Product-add-cart{
                        
                    }
                    &.Product-action{
                        text-align: center;
                        & a{
                            text-decoration: none;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

// Cart btn 
.cart-btn{
    border: 1px solid #ebebeb;
    padding: 10px 20px 20px;
    border-top: 0;

    @media #{$small-mobile}{
        padding: 10px 10px 20px;
    }

    & .btn{
        margin-top: 10px;
        margin-right: 15px;

        @media #{$small-mobile}{
            margin-right: 5px;
        }

        &:last-child{
            margin-left: 0;
        }
    }
}

// Cart Shipping 
.cart-shipping,
.cart-totals{
    margin-top: 48px;
}

// Cart Title 
.cart-title{
    & .title{
        color: $dark;
        font-size: 24px;
        text-transform: none;
        margin-bottom: 15px;

        @media #{$small-mobile}{
            font-size: 20px;
        }
    }
    & p{}
}


// Cart Totals 
.cart-totals{
    & .cart-total-table{
        background-color: #ebebeb;
        padding: 10px 25px;
        margin-bottom: 30px;
        
        & .table{
            margin-bottom: 0;

            & tbody{
                & tr{
                    & td{
                        padding: 5px 0;
                        border-top: 0;
                    }
                    &:last-child{
                        & td{
                            border-bottom: 1px;
                        }
                    }
                }
            }
        }

        & .shipping-list{
            padding: 0;
            margin: 0;
            list-style: none;
        }

        & .value{
            font-size: 14px;
            font: 400px;
            color: $black;
            text-transform: capitalize;
        }
        & .price{
            font-size: 14px;
            font: 400px;
            color: $black;
        }        
    }
}


// Empty Cart
.empty-cart{
    & .empty-cart-title{        
        color: $dark;
        font-size: 35px;        

        @media #{$large-mobile}{
            font-size: 24px;
        }
    }
    & .empty-cart-img{
        width: 110px;
        margin: 0 auto;
        padding-top: 25px;
    }
    & p{
        font-size: 16px;
        text-transform: capitalize;
        margin-top: 30px;
        color: $dark;
    }
    & .btn{
        margin-top: 20px;
    }
}