/*--
/*  6.2 - Sidebar CSS
/*----------------------------------------*/

// Sidebar Widget
.sidebar-widget{
    margin-top: 40px;

    & .widget-title{
        font-size: 20px;
        line-height: 1;
        color: $dark;
        padding-bottom: 20px;
        font-weight: 600;
        position: relative;
        display: block;
        border-bottom: 1px solid #ebebeb;
        text-transform: uppercase;
        
        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 60px;
            height: 2px;
            background-color: $primary;
        }
    }
}

// Widget Item
.widget-item{
    padding-top: 30px;

    & .title{
        display: block;
        color: #253237;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0px;
        line-height: 24px;
        text-transform: capitalize;        
    }

    & ul{
        & li{
            margin-top: 15px;
        }
    }
}

// Widget Checkbox
.widget-checkbox{
    & input[type="checkbox"]{
        display: none;

        & + label{
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            color: #555555;
            transition: all 0.3s linear;

            & span{
                width: 15px;
                height: 15px;
                border-radius: 3px;
                border: 1px solid #dfdfdf;
                display: inline-block;
                position: relative;
                margin-right: 8px;
                transition: all 0.3s linear;

                &::before{
                    content: "\f00c";
                    font-family: 'Line Awesome Free';
                    font-weight: 900;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    position: absolute;
                    line-height: 14px;
                    font-size: 13px;
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &:hover{
                color: $primary;

                & span{
                    border-color: $primary;
                }
            }
        }
        &:checked{
            & + label{
                & span{
                    &::before{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    &.checkbox-color{
        & input[type="checkbox"]{    
            & + label{    
                & span{
                    border: 1px solid transparent;
                    box-shadow: 2px 2px 4px 0 rgba($black,.2);

                    &::before{
                        display: none;
                    }
                }
                &:hover{
                    & span{
                        
                    }
                }
            }
            &:checked{
                & + label{
                    & span{

                    }
                }
            }
        }
    }
}

// Widget Price
.widget-price{
    padding-top: 12px;

    & input{
        border: 0;
        background: none;
        padding: 0;
    }

    & .ui-slider{
        border: 0;
        height: 5px;
        background-color: $white;
        margin-top: 20px;
        margin-bottom: 10px;
        margin-left: 10px;        

        & .ui-slider-range {
            background-color: $primary;
        }
        & .ui-slider-handle{
            top: -5px;
            width: 15px;
            height: 15px;
            border: none;
            background: $white;
            border-radius: 100%;
            box-shadow: 0px 0px 3px 0px rgba($black, 0.2);
            outline: none;
            cursor: pointer;
        }
    }
}

// Widget Price
.widget-tags{
    display: flex;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-right: -2px;
    padding-top: 12px;

    & a{
        display: block;
        padding: 10px 20px;
        font-size: 14px;
        line-height: 20px;
        color: #555555;
        font-weight: 400;
        border: 1px solid #ebebeb;
        border-radius: 30px;
        background: $white;
        margin: 4px;
        text-transform: capitalize;
        transition: all 0.3s linear;
        
        &:hover{
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }
    }
}

// Widget Search
.widget-search{
    position: relative;
    margin-top: 45px;

    & .form-control{
        height: 50px;
        border-radius: 5px;
        padding: 10px 15px;
        padding-right: 40px;
        border-color: #ebebeb;
        font-size: 14px;

        &:focus{
            box-shadow: none;
            border-color: $primary;
        }
    }
    & button{
        width: 50px;
        height: 50px;
        padding: 0;
        font-size: 24px;
        border: 0;
        background: none;
        position: absolute;
        top: 0;
        right: 0;
    }
}

// Sidebar link
.widget-link{
    padding-top: 15px;

    & ul{
        & li{
            & a{
                margin-top: 15px;
                font-weight: 400;
                color: #626262;
                display: block;
                font-size: 14px;
                text-transform: capitalize;
                transition: all 0.3s linear;

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}

// Widget Post
.widget-post{
    padding-top: 5px;

    & .single-post{
        display: flex;
        margin-top: 30px;

        & .post-thumb{
            & a{
                & img{
                    width: 90px;
                }
            }
        }
        & .post-content{
            flex: 1;
            padding-left: 20px;

            & .post-title{
                & a{
                    font-size: 14px;
                    color: $dark;
                    text-decoration: none;
                    transition: all 0.3s linear;
                    font-weight: 600;

                    &:hover{
                        color: $primary;
                    }
                }
            }
            & .date{
                font-size: 13px;
                color: #707070;
            }
        }
    }
}

// Sidebar Banner
.sidebar-banner{
    padding-top: 60px;
}
